// npm
import React, { memo } from 'react'
import { Divider as DividerBase, DividerProps, styled } from '@mui/material'

interface styleProps {
  colorVariant?: 'light-trans' | 'extra-light-trans' | 'dark-trans'
}

const StyledDivider = styled(DividerBase)(({ theme }) => ({
  backgroundColor: theme.palette.outline,
}))

interface Props extends styleProps, DividerProps {}

const Divider = (props: Props) => {
  return <StyledDivider {...props} />
}

export default memo(Divider)
