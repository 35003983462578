// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Box,
  Container,
  Stack,
  StackProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import loadable from '@loadable/component'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import ShareButton from '@atoms/buttons/ShareButton'
import TagButton from '@atoms/buttons/TagButton'
import ExternalButtonLink from '@atoms/buttonLinks/ExternalButtonLink'
import Divider from '@atoms/dividers/Divider'
import HeadingEnd from '@atoms/text/HeadingEnd'
import Markdown from '@atoms/text/Markdown'
import SmallHeading from '@atoms/text/SmallHeading'
import SectionProjectSliderTemplate from '@molecules/sections/sectionComponents/templates/SectionProjectSliderTemplate'
import Layout from '@organisms/global/Layout'

const SectionContentImage = loadable(
  () => import('@molecules/sections/sectionComponents/SectionContentImage')
)
const BrandingMockupCollection = loadable(
  () => import('@molecules/mockupCollections/BrandingMockupCollection')
)
const WebsiteMockupCollection = loadable(
  () => import('@molecules/mockupCollections/WebsiteMockupCollection')
)
const YoutubeMockupCollection = loadable(
  () => import('@molecules/mockupCollections/YoutubeMockupCollection')
)

// constants
import { firstSectionSpacing } from '@constants/firstSectionSpacing'

interface ActionButtonsProps extends StackProps {
  description: string
  buttonUrl: string
  buttonLabel: string
  altLayout?: boolean
}

interface Props {
  data: Queries.ProjectDetailQuery
  pageContext: PageProps & pageContextProps
}

const ActionButtons = ({
  description,
  buttonUrl,
  buttonLabel,
  altLayout,
}: ActionButtonsProps) => {
  return (
    <Stack direction="row">
      <ShareButton
        buttonProps={{
          sx: { mr: altLayout ? 0 : 1, order: altLayout ? 2 : 1 },
        }}
        url={typeof window !== 'undefined' ? window.location.href : ''}
        description={description}
      />
      {buttonUrl && buttonLabel && (
        <ExternalButtonLink
          href={buttonUrl}
          sx={{ mr: altLayout ? 1 : 0, order: altLayout ? 1 : 2 }}
        >
          {buttonLabel}
        </ExternalButtonLink>
      )}
    </Stack>
  )
}

const ProjectDetail = ({ data, pageContext }: Props) => {
  const theme = useTheme()
  const breakpoint = 'md'
  const breakpointUp = useMediaQuery(theme.breakpoints.up(breakpoint))

  const d = data.datoCmsProject
  const global = data.datoCmsGlobal

  // Tags for the meta title and description
  let seoTags = ''
  d.services.map((service, index) => {
    seoTags += `${index === 0 ? '' : ', '}${service.name}`
  })

  const labelGridProps = {
    xs: 2,
    xl: 3,
  }
  const contentGridProps = {
    xl: 9,
  }
  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: d.seoMetaTags,
        titlePrefix: d.client.name,
        extraTitle: seoTags,
        customDescription: `${d.seoMetadata.description}. ${seoTags}.`,
      }}
    >
      <Box component="section" bgcolor="secondary.dark" pb={{ xs: 6, xl: 16 }}>
        <Container maxWidth="xl">
          <Box pt={firstSectionSpacing} mb={{ xs: 4, xl: 10 }}>
            <Grid container spacing={0}>
              <Grid md={10} lg={8}>
                <SmallHeading
                  heading={d.client.name}
                  headingBoxProps={{ component: 'h1' }}
                  mb={{ xs: 2, sm: 3 }}
                />
                <Box
                  typography="h2"
                  color="white"
                  component="h2"
                  mt={0}
                  mb={{ xs: 2, sm: 3 }}
                >
                  {d.multilineTitle}
                  <HeadingEnd />
                </Box>
              </Grid>
              <Grid xs={10} md={8} lg={6}>
                <Box
                  typography="lgBody"
                  mb={{ xs: 1, sm: 2, xl: 3 }}
                  dangerouslySetInnerHTML={{
                    __html: d.leadText,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <GatsbyImage image={d.heroImage.gatsbyImageData} alt="" />

          <Divider sx={{ mt: { xs: 6, xl: 10 }, mb: 2.6 }} />
          <Grid container spacing={4}>
            <Grid {...labelGridProps}>
              <Stack
                direction="row"
                alignItems="center"
                height="100%"
                color="common.white"
              >
                Client:
              </Stack>
            </Grid>
            <Grid {...contentGridProps} xs={10}>
              <Stack direction="row" alignItems="center" width="100%">
                <Box mr="auto">{d.client.name}</Box>

                {breakpointUp && (
                  <ActionButtons
                    description={d.seoMetadata.description}
                    buttonLabel={d.projectLinkButtonLabel}
                    buttonUrl={d.projectLink}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2.6, mb: { xs: 4, xl: 8 } }} />
          <Grid container spacing={breakpointUp ? 4 : 2}>
            <Grid {...labelGridProps} sx={{ color: 'common.white' }}>
              Overview:
            </Grid>
            <Grid {...contentGridProps}>
              <Markdown text={d.overviewNode.childMarkdownRemark.html} />

              <Box mt={{ xs: 4, xl: 6 }}>
                {d.services.map(
                  (service: Queries.DatoCmsService, index: number) => {
                    return (
                      <TagButton
                        key={`project-detail-tag-${service.originalId}-${index}}`}
                        slug={service.slug}
                        apiKey={service.model.apiKey}
                        label={service.name}
                      />
                    )
                  }
                )}
              </Box>
              <Box sx={{ display: { xs: 'block', [breakpoint]: 'none' } }}>
                <Divider sx={{ mt: 2.6, mb: { xs: 4, xl: 8 } }} />
                <ActionButtons
                  description={d.seoMetadata.description}
                  buttonLabel={d.projectLinkButtonLabel}
                  buttonUrl={d.projectLink}
                  altLayout
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {d.mockupCollection.map((collectionType: any, index: number) => {
        if (collectionType.model.apiKey === 'website_mockup_collection') {
          const collection: Queries.DatoCmsWebsiteMockupCollection =
            collectionType
          return (
            <Box
              component="section"
              bgcolor="secondary.main"
              pt={{ xs: 6, xl: 16 }}
              pb={
                d.mockupCollection.length > 1 &&
                index < d.mockupCollection.length
                  ? { xs: 2, xl: 5 }
                  : { xs: 6, xl: 16 }
              }
            >
              <Container maxWidth="xl">
                <WebsiteMockupCollection
                  key={`mockup-collection-${collection.originalId}-${index}}`}
                  collection={collection}
                />
              </Container>
            </Box>
          )
        }
        if (collectionType.model.apiKey === 'branding_mockup_collection') {
          const collection: Queries.DatoCmsBrandingMockupCollection =
            collectionType
          return (
            <Box
              component="section"
              bgcolor="secondary.main"
              pt={index > 0 ? 0 : { xs: 6, xl: 16 }}
              pb={{ xs: 6, xl: 16 }}
            >
              <Container maxWidth="xl">
                <BrandingMockupCollection
                  key={`mockup-collection-${collection.originalId}-${index}}`}
                  collection={collection}
                />
              </Container>
            </Box>
          )
        }
        if (collectionType.model.apiKey === 'youtube_mockup_collection') {
          const collection: Queries.DatoCmsYoutubeMockupCollection =
            collectionType
          return (
            <Box
              component="section"
              bgcolor="secondary.main"
              py={{ xs: 0, md: 6, xl: 16 }}
            >
              <Container
                maxWidth="xl"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    paddingLeft: '0',
                    paddingRight: '0',
                  },
                }}
              >
                <YoutubeMockupCollection
                  key={`mockup-collection-${collection.originalId}-${index}}`}
                  youtubeUrl={collection.youtubeVideoUrl}
                  fallbackImage={collection.fallbackImage.url}
                />
              </Container>
            </Box>
          )
        }
      })}

      {d.quote && d.quote[0] && (
        <Box component="section" bgcolor="secondary.dark">
          <SectionContentImage
            xsImage={d.xsSummaryImage.gatsbyImageData}
            mdImage={d.mdSummaryImage.gatsbyImageData}
            lgImage={d.lgSummaryImage.gatsbyImageData}
            sectionContent={d.quote[0]}
            bgColor="secondary.dark"
            imageOpacity={d.summaryImageQuoteSectionOpacity}
          />
        </Box>
      )}
      <SectionProjectSliderTemplate
        smallHeading={global.otherProjectsSection[0].smallHeading}
        mainHeading={global.otherProjectsSection[0].mainHeading}
        text={global.otherProjectsSection[0].text}
        bgColor={global.otherProjectsSection[0].backgroundColor.name}
        button={global.otherProjectsSection[0].viewAllButton[0]}
        projects={data.allDatoCmsProject.edges}
      />
    </Layout>
  )
}

export const query = graphql`
  query ProjectDetail($originalId: String!) {
    datoCmsProject(originalId: { eq: $originalId }) {
      client {
        name
      }
      heroImage {
        gatsbyImageData(width: 1536, layout: FULL_WIDTH)
      }
      leadText
      projectLink
      projectLinkButtonLabel
      mockupCollection {
        ... on DatoCmsWebsiteMockupCollection {
          model {
            apiKey
          }
          desktopMockup {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
          tabletMockup1 {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
          tabletMockup2 {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
          phoneMockup1 {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
          phoneMockup2 {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
          phoneMockup3 {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
          phoneMockup4 {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
        }
        ... on DatoCmsBrandingMockupCollection {
          model {
            apiKey
          }
          mockups {
            gatsbyImageData(width: 1536, layout: FULL_WIDTH)
            alt
          }
        }
        ... on DatoCmsYoutubeMockupCollection {
          originalId
          model {
            apiKey
          }
          youtubeVideoUrl
          fallbackImage {
            url(imgixParams: { w: "1536" })
          }
        }
      }
      multilineTitle
      overviewNode {
        childMarkdownRemark {
          html
        }
      }
      summaryImageQuoteSectionOpacity
      quote {
        model {
          apiKey
        }
        quote {
          ...quote
        }
      }
      services {
        slug
        originalId
        name
        model {
          apiKey
        }
      }
      ...quoteBgImages
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      seoMetadata {
        description
      }
    }
    datoCmsGlobal {
      otherProjectsSection {
        ...sectionProjectSlider
      }
    }
    allDatoCmsProject(
      filter: { originalId: { ne: $originalId } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          ...projectCard
        }
      }
    }
  }
`

export default ProjectDetail
