import React from 'react'
import { styled } from '@mui/material'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa'

import SocialButtonLink from '@atoms/buttonLinks/SocialButtonLink'

interface ButtonClickProps {
  shareTo: 'Facebook' | 'Twitter' | 'LinkedIn'
  description: string
}

export interface ShareButtonsProps extends Omit<ButtonClickProps, 'shareTo'> {
  url: string
  hashtags?: string
}

const StyledSocialButtonLink = styled(SocialButtonLink)(({ theme }) => ({
  margin: '0 3px',
}))

const ShareButtons = ({ url, description, hashtags }: ShareButtonsProps) => {
  return (
    <>
      <StyledSocialButtonLink
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${description}&summary=${description}`}
        aria-label="Share via LinkedIn"
        icon={<FaLinkedinIn />}
      />
      <StyledSocialButtonLink
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        aria-label="Share via Facebook"
        icon={<FaFacebookF />}
      />
      <StyledSocialButtonLink
        href={`https://twitter.com/intent/tweet?url=${url}&text=${description}&via=Delta_Exec${
          hashtags ? `&hashtags=${hashtags}` : ''
        }`}
        aria-label="Share via Twitter"
        icon={<FaTwitter />}
      />
    </>
  )
}

export default ShareButtons
